import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

enum RbwPageQuery {
  all = "all",
  request = "request",
}

interface RbwStoreState {
  doc: { [key: string]: RbwDoc };
  pageQuery: { [key in RbwPageQuery]: Array<string> };
  textQuery: { [key: string]: Array<string> };
}

interface RbwDoc {
  id: string;
  data: RbwDocData;
  unsub: Unsubscribe | null;
}

interface RbwDocData {
  uid: string;
  rbwId: string;
  address: string; // otw
  status: string; // otw
  farmerName: string; // otw
  farmerContact: string; // otw
  buildingName: string;
  buildingImage: string;
  buildingAreas: number | null;
  buildingLength: number | null;
  buildingWidth: number | null;
  floorHeight: number | null;
  harvestPeriod: number | null;
  harvestWeight: number | null;
  certificate: {
    id: string;
    imageURL: string;
  };
  benefit: {
    code: string | null; // otw
    text: string; // otw
  };
  buildingSpecs: {
    foot_dipping: boolean;
    hair_cap: boolean;
    hand_glove: boolean;
    harvest_suit: boolean;
    harvesting_tools: boolean;
    storage_shelf: boolean;
    water_pool: boolean;
  };
  note: {
    request: string;
    survey1: string;
    survey2: string;
    verification: string;
    certification: string; // otw
  };
  dates: {
    registerDate: Timestamp | null; // otw
    verificationDate: Timestamp | null; // otw
    preliminaryStartDate: Timestamp | null; // otw
    preliminaryEndDate: Timestamp | null; // otw
    bkpStartDate: Timestamp | null; // otw
    bkpEndDate: Timestamp | null; // otw
    bkpVerificationDate: Timestamp | null; // otw
    finishDate: Timestamp | null; // otw
  };
  landOwnership: {
    code: number | null; // otw
    // entryCondition: {};
    text: string; // otw
  };
  locationMap: {
    formated_address: string;
    geohash: string; // otw
    geopoint: GeoPoint | null;
  };
}

@Module
export default class RbwModule extends VuexModule implements RbwStoreState {
  errorMessage = null;
  doc = {} as { [key: string]: RbwDoc };
  pageQuery = {} as { [key in RbwPageQuery]: Array<string> };
  textQuery = {} as { [key: string]: Array<string> };

  @Mutation
  [Mutations.RBW_SET_DOC](payload: {
    id: string;
    data: RbwDocData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      uid: data.uid ?? "",
      rbwId: id,
      address: data.address ?? "",
      status: data.status ?? "",
      farmerName: data.farmerName ?? "",
      farmerContact: data.farmerContact ?? "",
      buildingName: data.buildingName ?? "",
      buildingImage: data.buildingImage ?? "",
      buildingAreas: data.buildingAreas ?? null,
      buildingLength: data.buildingLength ?? null,
      buildingWidth: data.buildingWidth ?? null,
      floorHeight: data.floorHeight ?? null,
      harvestPeriod: data.harvestPeriod ?? null,
      harvestWeight: data.harvestWeight ?? null,
      certificate: {
        id: data.certificate?.id ?? "",
        imageURL: data.certificate?.imageURL ?? "",
      },
      benefit: {
        code: data.benefit?.code ?? null,
        text: data.benefit?.text ?? "",
      },
      buildingSpecs: {
        foot_dipping: data.buildingSpecs?.foot_dipping ?? false,
        hair_cap: data.buildingSpecs?.hair_cap ?? false,
        hand_glove: data.buildingSpecs?.hand_glove ?? false,
        harvest_suit: data.buildingSpecs?.harvest_suit ?? false,
        harvesting_tools: data.buildingSpecs?.harvesting_tools ?? false,
        storage_shelf: data.buildingSpecs?.storage_shelf ?? false,
        water_pool: data.buildingSpecs?.water_pool ?? false,
      },
      note: {
        request: data.note?.request ?? "",
        survey1: data.note?.survey1 ?? "",
        survey2: data.note?.survey2 ?? "",
        verification: data.note?.verification ?? "",
        certification: data.note?.certification ?? "",
      },
      dates: {
        verificationDate: data.dates?.verificationDate ?? null,
        preliminaryStartDate: data.dates?.preliminaryStartDate ?? null,
        preliminaryEndDate: data.dates?.preliminaryEndDate ?? null,
        bkpStartDate: data.dates?.bkpStartDate ?? null,
        bkpEndDate: data.dates?.bkpEndDate ?? null,
        bkpVerificationDate: data.dates?.bkpVerificationDate ?? null,
        finishDate: data.dates?.finishDate ?? null,
        registerDate: data.dates?.registerDate ?? null,
      },
      landOwnership: {
        code: data.landOwnership?.code ?? null,
        // entryCondition: {},
        text: data.landOwnership?.text ?? "",
      },
      locationMap: {
        formated_address: data.locationMap?.formated_address ?? "",
        geohash: data.locationMap?.geohash ?? "",
        geopoint: data.locationMap?.geopoint ?? null,
      },
    } as RbwDocData;
    if (unsub) {
      this.doc[id] = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]) {
      this.doc[id].data = docData;
    } else {
      console.error(`FAILED SETTING RBW DOC`);
    }
  }

  @Action({ rawError: true })
  [Actions.RBW_GET_SINGLE](payload: { rbwid: string }) {
    const rbwid = payload.rbwid;
    if (!this.doc[rbwid]?.unsub) {
      const unsub = onSnapshot(doc(db, "rbws", rbwid), (dbDoc) => {
        this.context.commit(Mutations.RBW_SET_DOC, {
          id: dbDoc.id,
          data: dbDoc.data() ?? {},
          unsub: unsub,
        });
      });
    }
  }

  @Mutation
  [Mutations.RBW_SET_PAGE_QUERY](payload: {
    key: RbwPageQuery;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Mutation
  [Mutations.RBW_SET_TEXT_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.textQuery[key]) {
      this.textQuery[key] = [...this.textQuery[key], ...ids];
    } else {
      this.textQuery[key] = ids;
    }
  }

  @Action
  [Actions.RBW_POPULATE_PAGE_QUERY](payload: {
    key: RbwPageQuery;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    let dbQuery: Query<DocumentData>;
    switch (key) {
      case RbwPageQuery.request:
        dbQuery = query(
          collection(db, "rbws"),
          where("versionNo", "==", 0),
          orderBy("dates.verificationDate", "desc"),
          // limit(50)
        );
        break;
      case RbwPageQuery.all:
      default:
        dbQuery = query(
          collection(db, "rbws"),
          where("versionNo", "==", 0),
          orderBy("dates.registerDate", "desc"),
          // orderBy("date.inputDate"),
          // limit(50)
        );
        break;
    }

    getDocs(dbQuery).then((dbDocs) => {
      const ids: Array<string> = [];

      dbDocs.forEach((dbDoc) => {
        ids.push(dbDoc.id);
        if (!this.doc[dbDoc.id]) {
          const unsub = onSnapshot(doc(db, "rbws", dbDoc.id), (dbDoc) => {
            this.context.commit(Mutations.RBW_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
            });
          });
          this.context.commit(Mutations.RBW_SET_DOC, {
            id: dbDoc.id,
            data: dbDoc.data(),
            unsub: unsub,
          });
        }
      });

      this.context.commit(Mutations.RBW_SET_PAGE_QUERY, {
        key: key,
        ids: ids,
        append: append,
      });
    }).catch((error) => console.log("owo", error.message));
  }

  @Action
  async [Actions.RBW_POPULATE_TEXT_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, "rbws"),
      where("versionNo", "==", 0),
      orderBy("farmerName"),
      where("farmerName", "==", key),
      where("farmerName", "==", key + "~"),
      // limit(50)
    );

    const dbDocs = await getDocs(dbQuery);
    const ids: Array<string> = [];

    dbDocs.forEach((dbDoc) => {
      ids.push(dbDoc.id);
      if (!this.doc[dbDoc.id]) {
        this.context.commit(Mutations.RBW_SET_DOC, {
          id: dbDoc.id,
          data: dbDoc.data(),
          unsub: onSnapshot(doc(db, "rbws", dbDoc.id), (dbDoc) => {
            this.context.commit(Mutations.RBW_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
            });
          }),
        });
      }
    });

    this.context.commit(Mutations.RBW_SET_TEXT_QUERY, {
      key: key,
      ids: ids,
      append: append,
    });
  }

  @Action
  [Actions.RBW_UPDATE_DOC](payload: { id: string; rbw: { [x: string]: any } }) {
    const id = payload.id;
    const rbw = payload.rbw;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const rbwRef = doc(db, "rbws", id);
    batch.update(rbwRef, rbw);

    console.log(id, rbw);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.RBW_CREATE](payload: { id: string; rbw: DocumentData }) {
    const id = payload.id;
    const rbw = payload.rbw;

    // Get a new write batch
    const batch = writeBatch(db);

    if (rbw) {
      // Set profile value
      const rbwRef = doc(db, "rbws", id);
      batch.set(rbwRef, {
        versionNo: 0,
        ...rbw,
      });
    }

    // Commit the batch
    return batch.commit();
  }
}

export { RbwPageQuery, RbwStoreState, RbwDoc, RbwDocData };
