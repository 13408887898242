import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

export interface PriceStoreState {
  initialized: boolean,
  unsub: Unsubscribe | null,
  mangkok1Price: number,
  mangkok1ImageURL: string,
  mangkok2Price: number,
  mangkok2ImageURL: string,
  mangkok3Price: number,
  mangkok3ImageURL: string,
  oval1Price: number,
  oval1ImageURL: string,
  oval2Price: number,
  oval2ImageURL: string,
  oval3Price: number,
  oval3ImageURL: string,
  patahan1Price: number,
  patahan1ImageURL: string,
  patahan2Price: number,
  patahan2ImageURL: string,
  patahan3Price: number,
  patahan3ImageURL: string,
  sudut1Price: number,
  sudut1ImageURL: string,
  sudut2Price: number,
  sudut2ImageURL: string,
  sudut3Price: number,
  sudut3ImageURL: string,
}

@Module
export default class PriceModule
  extends VuexModule
  implements PriceStoreState
{
  initialized = false;
  unsub = null as Unsubscribe | null;
  mangkok1Price = 0;
  mangkok1ImageURL = "";
  mangkok2Price = 0;
  mangkok2ImageURL = "";
  mangkok3Price = 0;
  mangkok3ImageURL = "";
  oval1Price = 0;
  oval1ImageURL = "";
  oval2Price = 0;
  oval2ImageURL = "";
  oval3Price = 0;
  oval3ImageURL = "";
  patahan1Price = 0;
  patahan1ImageURL = "";
  patahan2Price = 0;
  patahan2ImageURL = "";
  patahan3Price = 0;
  patahan3ImageURL = "";
  sudut1Price = 0;
  sudut1ImageURL = "";
  sudut2Price = 0;
  sudut2ImageURL = "";
  sudut3Price = 0;
  sudut3ImageURL = "";
  
  
  @Mutation
  [Mutations.PRICE_SET_INIT](payload: boolean | undefined) {
    this.initialized = !!payload;
  }
  @Mutation
  [Mutations.PRICE_SET_DOC](payload: {
    price: DocumentData;
    unsub: Unsubscribe;
  }) {
    const price = payload.price;
    const unsub = payload.unsub;

    if (price) {
      this.mangkok1Price = price.swallowNest?.mangkok1?.price ?? 0
      this.mangkok1ImageURL = price.swallowNest?.mangkok1?.imageURL ?? ""
      this.mangkok2Price = price.swallowNest?.mangkok2?.price ?? 0
      this.mangkok2ImageURL = price.swallowNest?.mangkok2?.imageURL ?? ""
      this.mangkok3Price = price.swallowNest?.mangkok3?.price ?? 0
      this.mangkok3ImageURL = price.swallowNest?.mangkok3?.imageURL ?? ""
      this.oval1Price = price.swallowNest?.oval1?.price ?? 0
      this.oval1ImageURL = price.swallowNest?.oval1?.imageURL ?? ""
      this.oval2Price = price.swallowNest?.oval2?.price ?? 0
      this.oval2ImageURL = price.swallowNest?.oval2?.imageURL ?? ""
      this.oval3Price = price.swallowNest?.oval3?.price ?? 0
      this.oval3ImageURL = price.swallowNest?.oval3?.imageURL ?? ""
      this.patahan1Price = price.swallowNest?.patahan1?.price ?? 0
      this.patahan1ImageURL = price.swallowNest?.patahan1?.imageURL ?? ""
      this.patahan2Price = price.swallowNest?.patahan2?.price ?? 0
      this.patahan2ImageURL = price.swallowNest?.patahan2?.imageURL ?? ""
      this.patahan3Price = price.swallowNest?.patahan3?.price ?? 0
      this.patahan3ImageURL = price.swallowNest?.patahan3?.imageURL ?? ""
      this.sudut1Price = price.swallowNest?.sudut1?.price ?? 0
      this.sudut1ImageURL = price.swallowNest?.sudut1?.imageURL ?? ""
      this.sudut2Price = price.swallowNest?.sudut2?.price ?? 0
      this.sudut2ImageURL = price.swallowNest?.sudut2?.imageURL ?? ""
      this.sudut3Price = price.swallowNest?.sudut3?.price ?? 0
      this.sudut3ImageURL = price.swallowNest?.sudut3?.imageURL ?? ""
    };
    if (unsub) {
      this.unsub?.();
      this.unsub = unsub;
    }
  }

  @Action({ rawError: true })
  async [Actions.PRICE_INIT]() {

    if(!this.initialized){
      this.context.commit(Mutations.PRICE_SET_INIT, true);
  
      const priceDocRef = doc(db, "apps", "price");
      const unsubPrice = onSnapshot(priceDocRef, (priceSnapshot) => {
        this.context.commit(Mutations.PRICE_SET_DOC, { price: priceSnapshot.data() ?? {} });
      });
      this.context.commit(Mutations.PRICE_SET_DOC, { unsub: unsubPrice });
    }
  }

  @Action({ rawError: true })
  [Actions.PRICE_UPDATE_DOC](payload: {
    price: { [x: string]: any };
  }) {
    console.log("PRICE_UPDATE_DOC");
    const price = payload.price;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const priceRef = doc(db, "apps", "price");
    batch.update(priceRef, price);

    console.log(price);

    // Commit the batch
    return batch.commit();
  }
}