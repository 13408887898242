import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  Unsubscribe,
  User,
} from "firebase/auth";
import { auth, db } from "@/core/services/FirebaseService";
import { AccountUpgradeStatus, ProfileDocData, ProfileRole, UserAuthorization, UserDocData, UserStatus } from "./AccountModule";
import { doc, DocumentData, onSnapshot } from "firebase/firestore";
import { SubscriptionType } from "./SubscriptionModule";
import { NavigationGuardNext } from "vue-router";

export interface AuthStoreState {
  errorMessage: string | null;
  auth: User | null;
  user: {
    doc: UserDocData | null;
    unsub: Unsubscribe | null;
  };
  profile: {
    doc: ProfileDocData | null;
    unsub: Unsubscribe | null;
  };
}

@Module
export default class AuthModule extends VuexModule implements AuthStoreState {
  errorMessage = null;
  auth: User | null = null;
  user = {
    doc: null,
    unsub: null,
  } as AuthStoreState["user"];
  profile = {
    doc: null,
    unsub: null,
  } as AuthStoreState["profile"];

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isAuthenticated(): boolean {
    return this.auth !== null;
  }

  @Mutation
  [Mutations.AUTH_SET_ERROR](errorMessage) {
    this.errorMessage = errorMessage;
    console.log("AUTH_SET_ERROR", errorMessage)
  }

  @Mutation
  [Mutations.AUTH_SET_AUTH](auth: User) {
    this.auth = auth;
    // this.errorMessage = null;
    console.log("AUTH_SET_AUTH")
  }

  @Mutation
  [Mutations.AUTH_SET_USER](paload: {data: DocumentData, unsub: Unsubscribe | undefined}) {
    const data = paload.data
    const unsub = paload.unsub
    if (data) this.user.doc = {
      uid: data.uid ?? this.auth?.uid ?? "",
      email: data.email ?? this.auth?.email ?? "",
      authorization: data.authorization ?? UserAuthorization.appUser,
      registerDate: data.registerDate ?? null,
      lastOnlineDate: data.lastOnlineDate ?? null,
      purchase: {
        total: data.purchase?.total ?? null,
        lastDate: data.purchase?.lastDate ?? null,
      },
      sale: {
        total: data.sale?.total ?? 0,
        lastDate: data.sale?.lastDate ?? null,
      },
      subscription: {
        type: data.subscription?.type ?? SubscriptionType.free,
        endDate: data.subscription?.endDate ?? null,
      },
      status: data.status ?? UserStatus.active,
      location: {
        geopoint: data.location?.geopoint ?? null,
        address: data.location?.address ?? "",
      },
    };
    if (unsub) {
      this.user.unsub?.()
      this.user.unsub = unsub
    };
    console.log("user", data)
  }

  @Mutation
  [Mutations.AUTH_SET_PROFILE](paload: {data: DocumentData, unsub: Unsubscribe | undefined}) {
    const data = paload.data
    const unsub = paload.unsub
    const uwu = "".length > 0
    if (data) this.profile.doc = {
      uid: data.uid ?? this.auth?.uid ?? "",
      photoURL: data.photoURL?.length > 0 ? data.photoURL : (data.imageURL?.length > 0 ? data.imageURL : (this.auth?.photoURL ?? "")),
      name: data.name ?? this.auth?.displayName ?? "",
      role: data.role ?? ProfileRole.consumer,
      phone: data.phone ?? "",
      address: data.address ?? "",
      upgrade: {
        lastUpdated: data.upgrade?.lastUpdated ?? null,
        receiptImageURL: data.upgrade?.receiptImageURL ?? "",
        price: data.upgrade?.price ?? 0,
        type:  (data.upgrade?.type in SubscriptionType) ? data.upgrade?.type : SubscriptionType.free,
        status:  (data.upgrade?.status in AccountUpgradeStatus) ? data.upgrade?.status : AccountUpgradeStatus.onProgress,
        duration: {
          period: 6,
          unit: "month",
        }
      }
    };
    if (unsub) {
      this.profile.unsub?.()
      this.profile.unsub = unsub
    };
    console.log("profile", data)
  }

  @Mutation
  [Mutations.AUTH_PURGE_AUTH](errorMessage) {
    this.auth = null;
    this.user.unsub?.();
    this.profile.unsub?.();
    this.user = {
      doc: null,
      unsub: null,
    };
    this.profile = {
      doc: null,
      unsub: null,
    };
    this.errorMessage = errorMessage ?? null;
    console.log("AUTH_PURGE_AUTH", errorMessage)
  }

  // @Action
  // [Actions.LOGIN](credentials) {
  //   return ApiService.post("login", credentials)
  //     .then(({ data }) => {
  //       this.context.commit(Mutations.AUTH_SET_USER, data);
  //     })
  //     .catch(({ response }) => {
  //       this.context.commit(Mutations.AUTH_SET_ERROR, response.data.errors);
  //     });
  // }

  @Action
  async [Actions.AUTH_LOGIN](credentials) {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );
      const user = userCredential.user;
      this.context.commit(Mutations.AUTH_SET_AUTH, userCredential.user);

      const unsubUser = onSnapshot(doc(db, "users", user.uid), (dbDoc) => {
        this.context.commit(Mutations.AUTH_SET_USER, {
          id: dbDoc.id,
          data: dbDoc.data(),
        });
      });
      this.context.commit(Mutations.AUTH_SET_USER, {  unsub: unsubUser });
      const unsubProfile = onSnapshot(
        doc(db, "profiles", user.uid),
        (dbDoc) => {
          this.context.commit(Mutations.AUTH_SET_PROFILE, {
            id: dbDoc.id,
            data: dbDoc.data(),
          });
        }
      );
      this.context.commit(Mutations.AUTH_SET_PROFILE, { unsub: unsubProfile  });
      return auth.updateCurrentUser(user);
    } catch (error: any) {
      let errorMessage = "Login gagal";
      switch (error?.code) {
        case "auth/invalid-email":
          errorMessage = "Penulisan email salah";
          break;
        case "auth/user-not-found":
          errorMessage = "Email belum terdaftar";
          break;
        case "auth/wrong-password":
          errorMessage = "Password Salah";
          break;
        default:
          errorMessage = "Login gagal";
          break;
      }
      this.context.commit(Mutations.AUTH_SET_ERROR, errorMessage);
    }
  }

  @Action
  [Actions.AUTH_LOGOUT](errorMessage) {
    this.context.commit(Mutations.AUTH_PURGE_AUTH, errorMessage);
  }

  @Action
  [Actions.AUTH_REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.AUTH_SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.AUTH_SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.AUTH_FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.AUTH_SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.AUTH_SET_ERROR, response.data.errors);
      });
  }

  @Action
  async [Actions.AUTH_VERIFY_USER]() {
    const user = await new Promise<User | null>((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(
        auth,
        (user) => {
          unsubscribe();
          resolve(user);
        },
        reject
      );
    });

    if (user) {
      this.context.commit(Mutations.AUTH_SET_AUTH, user);
      if(!this.user.unsub){
        const unsubUser = onSnapshot(doc(db, "users", user.uid), (dbDoc) => {
          this.context.commit(Mutations.AUTH_SET_USER, {
            id: dbDoc.id,
            data: dbDoc.data(),
          });
        });
        this.context.commit(Mutations.AUTH_SET_USER, {  unsub: unsubUser });
      }
      if(!this.profile.unsub){
        const unsubProfile = onSnapshot(
          doc(db, "profiles", user.uid),
          (dbDoc) => {
            this.context.commit(Mutations.AUTH_SET_PROFILE, {
              id: dbDoc.id,
              data: dbDoc.data(),
            });
          }
        );
        this.context.commit(Mutations.AUTH_SET_PROFILE, { unsub: unsubProfile  });
      }
    } else {
      this.context.commit(Mutations.AUTH_SET_ERROR, "Unauthorized");
      this.context.commit(Mutations.AUTH_PURGE_AUTH);
    }

    

    return user;
  }
}
