import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import { collection, doc, DocumentData, onSnapshot, query, where } from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

export enum Province {
  all = 0,
  aceh = 11,
  sumateraUtara = 12,
  sumateraBarat = 13,
  riau = 14,
  jambi = 15,
  sumateraSelatan = 16,
  bengkulu = 17,
  lampung = 18,
  kepulauanBangkaBelitung = 19,
  kepulauanRiau = 21,
  dkiJakarta = 31,
  jawaBarat = 32,
  jawaTengah = 33,
  diYogyakarta = 34,
  jawaTimur = 35,
  banten = 36,
  bali = 51,
  nusaTenggaraBarat = 52,
  nusaTenggaraTimur = 53,
  kalimantanBarat = 61,
  kalimantanTengah = 62,
  kalimantanSelatan = 63,
  kalimantanTimur = 64,
  kalimantanUtara = 65,
  sulawesiUtara = 71,
  sulawesiTengah = 72,
  sulawesiSelatan = 73,
  sulawesiTenggara = 74,
  gorontalo = 75,
  sulawesiBarat = 76,
  maluku = 81,
  malukuUtara = 82,
  papua = 91,
  papuaBarat = 92,
}

export interface AnalyticStoreState {
  initialized:  boolean;
  account: {
    data: AccountAnalytic;
    unsub: Unsubscribe | null;
  };
  sale: {
    data: SalesAnalytic;
    unsub: Unsubscribe | null;
  };
  purchase: {
    data: PurchaseAnalytic;
    unsub: Unsubscribe | null;
  };
  harvest: {
    data: HarvestAnalytic;
    unsub: Unsubscribe | null;
  };
}

export interface AccountAnalytic {
  all: AccountAnalyticData;
  yearly: { [key: string]: AccountAnalyticData };
  monthly: { [key: string]: AccountAnalyticData };
  weekly: { [key: string]: AccountAnalyticData };
  daily: { [key: string]: AccountAnalyticData };
}

export interface AccountAnalyticData {
  total: number;

  appuser: number;
  superteam: number;
  admin: number;

  consumer: number;
  farmer: number;

  basic: number;
  partner: number;
  foster: number;

  suspended: number;
  deleted: number;
}

export interface SalesAnalytic {
  all: SaleAnalyticData;
  yearly: { [key: string]: SaleAnalyticData };
  monthly: { [key: string]: SaleAnalyticData };
  weekly: { [key: string]: SaleAnalyticData };
  daily: { [key: string]: SaleAnalyticData };
}

export interface SaleAnalyticData {
  val: number;
  freq: number;
}

export interface PurchaseAnalytic {
  all: PurchaseAnalyticData;
  yearly: { [key: string]: PurchaseAnalyticData };
  monthly: { [key: string]: PurchaseAnalyticData };
  weekly: { [key: string]: PurchaseAnalyticData };
  daily: { [key: string]: PurchaseAnalyticData };
}

export interface PurchaseAnalyticData {
  total: {
    val: number;
    freq: number;
  };
  consumer: {
    val: number;
    freq: number;
  };
  farmer: {
    val: number;
    freq: number;
  };
}

export interface HarvestAnalytic {
  all: { [key in Province]: HarvestAnalyticData };
  yearly: { [key: string]: { [key in Province]: HarvestAnalyticData } };
  monthly: { [key: string]: { [key in Province]: HarvestAnalyticData } };
  weekly: { [key: string]: { [key in Province]: HarvestAnalyticData } };
  daily: { [key: string]: { [key in Province]: HarvestAnalyticData } };
}

export interface HarvestAnalyticData {
  val: number;
  freq: number;
  weight: number;
}

@Module
export default class AnalyticModule
  extends VuexModule
  implements AnalyticStoreState
{
  initialized = false;
  account = {
    data: {
      all: {
        total: 0,
        consumer: 0,
        farmer: 0,
        basic: 0,
        partner: 0,
        foster: 0,
        appuser: 0,
        admin: 0,
        superteam: 0,
        suspended: 0,
        deleted: 0,
      },
      yearly: {},
      monthly: {},
      weekly: {},
      daily: {},
    },
    unsub: null,
  } as AnalyticStoreState["account"];
  sale = {
    data: {
      all: {
        val: 0,
        freq: 0,
      },
      yearly: {},
      monthly: {},
      weekly: {},
      daily: {},
    },
    unsub: null,
  } as AnalyticStoreState["sale"];
  purchase = {
    data: {
      all: {
        total: {
          val: 0,
          freq: 0,
        },
        consumer: {
          val: 0,
          freq: 0,
        },
        farmer: {
          val: 0,
          freq: 0,
        },
      },
      yearly: {},
      monthly: {},
      weekly: {},
      daily: {},
    },
    unsub: null,
  } as AnalyticStoreState["purchase"];
  harvest = {
    data: {
      all: {
        [Province.all]: {
          val: 0,
          freq: 0,
          weight: 0,
        },
      },
      yearly: {},
      monthly: {},
      weekly: {},
      daily: {},
    },
    unsub: null,
  } as AnalyticStoreState["harvest"];

  @Mutation
  [Mutations.ANALYTIC_SET_INIT](payload: boolean | undefined) {
    this.initialized = !!payload;
  }
  @Mutation
  [Mutations.ANALYTIC_SET_ACCOUNT](payload: {
    account: AccountAnalytic;
    unsub: Unsubscribe;
  }) {
    const account = payload.account;
    const unsub = payload.unsub;

    if (account) this.account.data = account;
    if (unsub) {
      this.account.unsub?.();
      this.account.unsub = unsub;
    }
  }
  @Mutation
  [Mutations.ANALYTIC_SET_SALES](payload: {
    sales: SalesAnalytic;
    unsub: Unsubscribe;
  }) {
    const sales = payload.sales;
    const unsub = payload.unsub;

    if (sales) this.sale.data = sales;
    if (unsub) {
      this.sale.unsub?.();
      this.sale.unsub = unsub;
    }
  }
  @Mutation
  [Mutations.ANALYTIC_SET_PURCHASE](payload: {
    purchase: PurchaseAnalytic;
    unsub: Unsubscribe;
  }) {
    const purchase = payload.purchase;
    const unsub = payload.unsub;

    if (purchase) this.purchase.data = purchase;
    if (unsub) {
      this.purchase.unsub?.();
      this.purchase.unsub = unsub;
    }
  }
  @Mutation
  [Mutations.ANALYTIC_SET_HARVEST](payload: {
    harvest: HarvestAnalytic;
    unsub: Unsubscribe;
  }) {
    const harvest = payload.harvest;
    const unsub = payload.unsub;

    if (harvest) this.harvest.data = harvest;
    if (unsub) {
      this.harvest.unsub?.();
      this.harvest.unsub = unsub;
    }
  }

  @Action({ rawError: true })
  async [Actions.ANALYTIC_INIT]() {
    console.log("OwO")
    this.context.commit(Mutations.ANALYTIC_SET_INIT, true);

    const accountQuery = query(
      collection(db, "apps/analytics/accountAnalytics"),
      // where("versionNo", "==", 0)
    );
    const saleQuery = query(
      collection(db, "apps/analytics/saleAnalytics"),
      where("versionNo", "==", 0)
    );
    const purchaseQuery = query(
      collection(db, "apps/analytics/purchaseAnalytics"),
      where("versionNo", "==", 0)
    );
    const harvestQuery = query(
      collection(db, "apps/analytics/harvestAnalytics"),
      where("versionNo", "==", 0)
    );

    console.log("OwO1")
    const unsubAccount = onSnapshot(accountQuery, (accountSnapshot) => {
      console.log("OwO2")
      const account: AccountAnalytic = {
        all: {
          total: 0,
          consumer: 0,
          farmer: 0,
          basic: 0,
          partner: 0,
          foster: 0,
          appuser: 0,
          admin: 0,
          superteam: 0,
          suspended: 0,
          deleted: 0,
        },
        yearly: {},
        monthly: {},
        weekly: {},
        daily: {},
      };
      console.log(accountSnapshot.size)
      accountSnapshot.docChanges().forEach((accountChange) => {
        console.log(accountChange.doc.id, accountChange.doc.data())
        account.all = accountChange.doc.data()?.all ?? account.all;
        account.yearly = { ...account.yearly, ...(accountChange.doc.data()?.yearly ?? {}) };
        account.monthly = {
          ...account.monthly,
          ...(accountChange.doc.data()?.monthly ?? {}),
        };
        account.weekly = { ...account.weekly, ...(accountChange.doc.data()?.weekly ?? {}) };
        account.daily = { ...account.daily, ...(accountChange.doc.data()?.daily ?? {}) };
      });

      this.context.commit(Mutations.ANALYTIC_SET_ACCOUNT, { account: account });
    }, (error) => {
      console.log("XwX", error)
    });
    this.context.commit(Mutations.ANALYTIC_SET_ACCOUNT, { unsub: unsubAccount });

    const unsubSales = onSnapshot(saleQuery, (salesSnapshot) => {
      const sales: SalesAnalytic = {
        all: {
          val: 0,
          freq: 0,
        },
        yearly: {},
        monthly: {},
        weekly: {},
        daily: {},
      };
      salesSnapshot.docChanges().forEach((salesChange) => {
        sales.all = salesChange.doc.data()?.all ?? sales.all;
        sales.yearly = { ...sales.yearly, ...(salesChange.doc.data()?.yearly ?? {}) };
        sales.monthly = {
          ...sales.monthly,
          ...(salesChange.doc.data()?.monthly ?? {}),
        };
        sales.weekly = { ...sales.weekly, ...(salesChange.doc.data()?.weekly ?? {}) };
        sales.daily = { ...sales.daily, ...(salesChange.doc.data()?.daily ?? {}) };
      });

      this.context.commit(Mutations.ANALYTIC_SET_SALES, { sales: sales });
    });
    this.context.commit(Mutations.ANALYTIC_SET_SALES, { unsub: unsubSales });

    const unsubPurchase = onSnapshot(purchaseQuery, (purchaseSnapshot) => {
      const purchase: PurchaseAnalytic = {
        all: {
          total: {
            val: 0,
            freq: 0,
          },
          consumer: {
            val: 0,
            freq: 0,
          },
          farmer: {
            val: 0,
            freq: 0,
          },
        },
        yearly: {},
        monthly: {},
        weekly: {},
        daily: {},
      };
      purchaseSnapshot.docChanges().forEach((purchaseChange) => {
        purchase.all = purchaseChange.doc.data()?.all ?? purchase.all;
        purchase.yearly = {
          ...purchase.yearly,
          ...(purchaseChange.doc.data()?.yearly ?? {}),
        };
        purchase.monthly = {
          ...purchase.monthly,
          ...(purchaseChange.doc.data()?.monthly ?? {}),
        };
        purchase.weekly = {
          ...purchase.weekly,
          ...(purchaseChange.doc.data()?.weekly ?? {}),
        };
        purchase.daily = {
          ...purchase.daily,
          ...(purchaseChange.doc.data()?.daily ?? {}),
        };
      });

      this.context.commit(Mutations.ANALYTIC_SET_PURCHASE, { sales: purchase });
    });
    this.context.commit(Mutations.ANALYTIC_SET_PURCHASE, {
      unsub: unsubPurchase,
    });

    const unsubHarvest = onSnapshot(harvestQuery, (harvestSnapshot) => {
      const harvest: HarvestAnalytic = {
        all: {
          [Province.all]: {
            val: 0,
            freq: 0,
            weight: 0,
          },
        },
        yearly: {},
        monthly: {},
        weekly: {},
        daily: {},
      } as HarvestAnalytic;
      harvestSnapshot.docChanges().forEach((harvestChange) => {
        harvest.all = harvestChange.doc.data()?.all ?? harvest.all;
        harvest.yearly = {
          ...harvest.yearly,
          ...(harvestChange.doc.data()?.yearly ?? {}),
        };
        harvest.monthly = {
          ...harvest.monthly,
          ...(harvestChange.doc.data()?.monthly ?? {}),
        };
        harvest.weekly = {
          ...harvest.weekly,
          ...(harvestChange.doc.data()?.weekly ?? {}),
        };
        harvest.daily = {
          ...harvest.daily,
          ...(harvestChange.doc.data()?.daily ?? {}),
        };
      });

      this.context.commit(Mutations.ANALYTIC_SET_HARVEST, { sales: harvest });
    });
    this.context.commit(Mutations.ANALYTIC_SET_HARVEST, {
      unsub: unsubHarvest,
    });
  }
}
