import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule, { AuthStoreState } from "@/store/modules/AuthModule";
import BodyModule, { BodyStoreState } from "@/store/modules/BodyModule";
import BreadcrumbsModule, {
  BreadcrumbsStoreState,
} from "@/store/modules/BreadcrumbsModule";
import ConfigModule, { ConfigStoreState } from "@/store/modules/ConfigModule";
import ThemeModeModule, {
  ThemeModeStoreState,
} from "@/store/modules/ThemeModeModule";
import AccountModule, { AccountStoreState } from "./modules/AccountModule";
import SubscriptionModule, { SubscriptionStoreState } from "./modules/SubscriptionModule";
import RbwModule, { RbwStoreState } from "./modules/RbwModule";
import HarvestModule, { HarvestStoreState } from "./modules/HarvestModule";
import ProductModule, { ProductStoreState } from "./modules/ProductModule";
import SeminarModule, { SeminarStoreState } from "./modules/SeminarModule";
import ParticipantModule, {
  ParticipantStoreState,
} from "./modules/ParticipantModule";
import ArticleModule, { ArticleStoreState } from "./modules/ArticleModule";
import ConsultationModule, {
  ConsultationStoreState,
} from "./modules/ConsultationModule";
import ChatModule, { ChatStoreState } from "./modules/ChatModule";
import CourseModule, { CourseStoreState } from "./modules/CourseModule";
import TransactionModule, {
  TransactionStoreState,
} from "./modules/TransactionModule";
import AnalyticModule, {
  AnalyticStoreState,
} from "./modules/AnalyticModule";
import PriceModule, {
  PriceStoreState,
} from "./modules/PriceModule";

config.rawError = true;

// define your typings for the store state
export interface StoreState {
  BodyModule: BodyStoreState;
  BreadcrumbsModule: BreadcrumbsStoreState;
  ConfigModule: ConfigStoreState;
  ThemeModeModule: ThemeModeStoreState;

  AuthModule: AuthStoreState;
  AccountModule: AccountStoreState;
  SubscriptionModule: SubscriptionStoreState;
  RbwModule: RbwStoreState;
  HarvestModule: HarvestStoreState;
  ProductModule: ProductStoreState;
  SeminarModule: SeminarStoreState;
  ParticipantModule: ParticipantStoreState;
  ArticleModule: ArticleStoreState;
  ConsultationModule: ConsultationStoreState;
  ChatModule: ChatStoreState;
  CourseModule: CourseStoreState;
  TransactionModule: TransactionStoreState;
  AnalyticModule: AnalyticStoreState;
  PriceModule: PriceStoreState;
}

// define injection key
export const storeKey: InjectionKey<Store<StoreState>> = Symbol();

const store = createStore<StoreState>({
  modules: {
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ThemeModeModule,

    AuthModule, // done
    AccountModule, // done
    SubscriptionModule, // done
    RbwModule, // done
    HarvestModule, // done
    ProductModule, // done
    SeminarModule, // done
    ParticipantModule, // done
    ArticleModule, // done
    ConsultationModule, // done
    ChatModule, // done
    CourseModule,
    TransactionModule, // done

    AnalyticModule, // done
    PriceModule, // done
  },
});

export default store;
