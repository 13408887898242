import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

enum HarvestPageQuery {
  all = "all",
  finished = "finished",
  request = "request",
  receive = "receive",
  delivery = "delivery",
  process = "process",
}

export enum HarvestStage {
  finished = "finished",
  request = "request",
  receive = "receive",
  delivery = "delivery",
  process = "process",
}

export enum HarvestStatus {
  cancelled = "cancelled",
  rejected = "rejected",
  failed = "failed",
  finished = "finished",
  ongoing = "ongoing",
}

export enum SwallowNestType {
  mangkok1 = "mangkok1",
  mangkok2 = "mangkok2",
  mangkok3 = "mangkok3",
  oval1 = "oval1",
  oval2 = "oval2",
  oval3 = "oval3",
  patahan1 = "patahan1",
  patahan2 = "patahan2",
  patahan3 = "patahan3",
  sudut1 = "sudut1",
  sudut2 = "sudut2",
  sudut3 = "sudut3",
}

interface HarvestStoreState {
  searchText: string;
  doc: { [key: string]: HarvestDoc };
  pageQuery: { [key in HarvestPageQuery]: Array<string> };
  textQuery: { [key: string]: Array<string> };
  index: { [key: string]: HarvestIndex };
  indexUnsub: Unsubscribe | null;
  modal: { harvestEdit: { hid: string | null } };
}

export enum HarvestIndexStatus {
  requestStart,
  requestCancelled,
  requestRejected,
  requestEnd,
  receiveStart,
  receiveRejected,
  receiveEnd,
  deliveryStart,
  deliveryRejected,
  deliveryEnd,
  processStart,
  processReject,
  processEnd,
  all,
  null,
}

export interface HarvestIndex {
  hid: string;
  farmer: string | null;
  rbw: string | null;
  address: string | null;
  imageURL: string | null;
  date: Timestamp | null;
  status: HarvestIndexStatus;
}

interface HarvestDoc {
  id: string;
  data: HarvestDocData;
  unsub: Unsubscribe | null;
}

interface HarvestDocData {
  hid: string;
  stage: HarvestStage;
  status: HarvestStatus;
  lastUpdate: Timestamp | null;
  farmer: {
    email: string;
    formatedAddress: string;
    geopoint: GeoPoint | null;
    name: string;
    rbwid: string;
    rbwName: string;
    uid: string;
  };
  request: {
    harvestDate: Timestamp | null;
    harvestDetail: {
      [key in SwallowNestType]: {
        imageURL: string;
        imageURLs: Array<string>;
        weight: number;
      };
    };
    harvestHumidity: number;
    requestDate: Timestamp | null;
    isRejected: boolean;
    isCancelled: boolean;

    note: string;
    dateStart: Timestamp | null;
    dateEnd: Timestamp | null;
  };
  receive: {
    humidity: number;
    price: number;
    isRejected: boolean;
    
    item: {
      [key in SwallowNestType]: {
        imageURL: string;
        imageURLs: Array<string>;
        weight: number | null;
      };
    };

    note: string;
    dateEnd: Timestamp | null;
  };
  delivery: {
    factoryAddress: string;
    courier: string;
    receiptID: string;
    receiptImageURL: string;
    isFailed: boolean;
    
    item: {
      [key in SwallowNestType]: {
        imageURL: string;
        imageURLs: Array<string>;
        weight: number;
      };
    };

    price: number;
    weight: number;
    humidity: number;

    note: string;
    note2: string;
    dateStart: Timestamp | null;
    dateEnd: Timestamp | null;
  };
  process: {
    productMarketplace: { [key: string]: number };
    productOthers: { [key in SwallowNestType]: number };
    isFailed: boolean;

    note: string;
    dateStart: Timestamp | null;
    dateEnd: Timestamp | null;
    
    consumerProduct: {
      [key: string]: {
        qty: number;
      };
    };
    nestProduct: {
      [key in SwallowNestType]: {
        weight: number;
      };
    };
  };
}

const getHarvestStage = (harvest: HarvestDocData): HarvestStage => {
  if (harvest?.process?.dateEnd) {
    return HarvestStage.finished;
  } else if (harvest?.delivery?.dateEnd) {
    return HarvestStage.process;
  } else if (harvest?.receive?.dateEnd) {
    return HarvestStage.delivery;
  } else if (harvest?.request?.dateEnd) {
    return HarvestStage.receive;
  } else {
    return HarvestStage.request;
  }
};

const getHarvestStatus = (harvest: HarvestDocData): HarvestStatus => {
  if (harvest?.request.isCancelled) {
    return HarvestStatus.cancelled;
  } else if (harvest?.request?.isRejected) {
    return HarvestStatus.rejected;
  } else if (harvest?.receive?.isRejected) {
    return HarvestStatus.rejected;
  } else if (harvest?.delivery?.isFailed) {
    return HarvestStatus.failed;
  } else if (harvest?.process?.isFailed) {
    return HarvestStatus.failed;
  } if (harvest?.process?.dateEnd) {
    return HarvestStatus.finished;
  } else {
    return HarvestStatus.ongoing;
  }
};

@Module
export default class HarvestModule
  extends VuexModule
  implements HarvestStoreState
{
  errorMessage = null;
  searchText = "";
  doc = {} as { [key: string]: HarvestDoc };
  pageQuery = {} as { [key in HarvestPageQuery]: Array<string> };
  textQuery = {} as { [key: string]: Array<string> };
  index = {} as { [key: string]: HarvestIndex };
  indexUnsub = null as Unsubscribe | null;
  modal = { harvestEdit: { hid: null } } as HarvestStoreState["modal"];

  @Mutation
  [Mutations.HARVEST_SET_MODAL_EDIT](hid: string) {
    this.modal.harvestEdit.hid = hid ?? null
  }

  @Mutation
  [Mutations.HARVEST_SET_INDEX](payload: {
    id: string;
    data: DocumentData;
  }) {
    const id = payload.id;
    const data = payload.data;

    const docData = {
      hid: id,
      
      email: data.email ?? null,
      farmer: data.farmer ?? null,
      rbw: data.rbw ?? null,
      address: data.address ?? null,
  
      imageURL: data.imageURL ?? null,
      date: data.date ?? null,
      status: data.status ?? HarvestIndexStatus.null,
    } as HarvestIndex;

    if(id) this.index[id] = docData
    // console.log(docData)
  }

  @Mutation
  [Mutations.HARVEST_SET_INDEX_SUB](payload: {
    unsub: Unsubscribe;
  }) {
    const unsub = payload.unsub;
    this.indexUnsub = unsub ?? null
  }

  @Mutation
  [Mutations.HARVEST_SET_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      hid: id,
      stage: getHarvestStage(data as HarvestDocData),
      status: getHarvestStatus(data as HarvestDocData),
      lastUpdate: data.lastUpdate ?? null,
      farmer: {
        email: data.farmer?.email ?? "",
        formatedAddress: data.farmer?.formatedAddress ?? "",
        geopoint: data.farmer?.geopoint ?? null,
        name: data.farmer?.name ?? "",
        rbwid: data.farmer?.rbwId ?? data.farmer?.rbwid ?? "",
        rbwName: data.farmer?.rbwName ?? "",
        uid: data.farmer?.uid ?? "",
      },
      request: {
        harvestDate: data.request?.requestDate ?? null,
        harvestDetail: Object.entries<DocumentData>(
          data.request?.harvestDetail ?? {}
        ).reduce((res, cV) => {
          if (cV[0] in SwallowNestType)
            return {
              ...res,
              [cV[0]]: {
                imageURL: cV[1]?.imageURL ?? "",
                imageURLs: cV[1]?.imageURLs ?? [],
                weight: cV[1]?.weight ?? "",
              },
            };
          return res;
        }, {}),
        harvestHumidity: data.request?.harvestHumidity ?? null,
        requestDate: data.request?.requestDate ?? null,
        isRejected: data.request?.isRejected ?? false,
        isCancelled: data.request?.isCancelled ?? false,

        note: data.request?.note ?? "",
        dateStart: data.request?.requestDate ?? data.request?.dateStart ?? null,
        dateEnd: data.request?.dateEnd ?? null,
      },
      receive: {
        price: data.receive?.price ?? 0,
        humidity: data.receive?.humidity ?? 0,
        isRejected: data.receive?.isRejected ?? false,
        
        item: Object.entries<DocumentData>(
          data.receive?.item ?? {}
        ).reduce((res, cV) => {
          if (cV[0] in SwallowNestType)
            return {
              ...res,
              [cV[0]]: {
                imageURL: cV[1]?.imageURL ?? "",
                imageURLs: cV[1]?.imageURLs ?? [],
                weight: cV[1]?.weight ?? null,
              },
            };
          return res;
        }, {}),

        note: data.receive?.note ?? "",
        dateEnd: data.receive?.dateEnd ?? null,
      },
      delivery: {
        factoryAddress: data.delivery?.factoryAddress ?? "",
        courier: data.delivery?.courier ?? "",
        receiptID: data.delivery?.receiptID ?? "",
        receiptImageURL: data.delivery?.receiptImageURL ?? "",
        
        item: Object.entries<DocumentData>(
          data.delivery?.item ?? {}
        ).reduce((res, cV) => {
          if (cV[0] in SwallowNestType)
            return {
              ...res,
              [cV[0]]: {
                imageURL: cV[1]?.imageURL ?? "",
                imageURLs: cV[1]?.imageURLs ?? [],
                weight: cV[1]?.weight ?? "",
              },
            };
          return res;
        }, {}),

        price: data.delivery?.price ?? 0,
        weight: data.delivery?.weight ?? 0,
        humidity: data.delivery?.humidity ?? 0,
        isFailed: data.delivery?.isFailed ?? false,

        note: data.delivery?.note ?? "",
        note2: data.delivery?.note2 ?? "",
        dateStart: data.delivery?.dateStart ?? null,
        dateEnd: data.delivery?.dateEnd ?? null,
      },
      process: {
        productMarketplace: data.process?.productMarketplace ?? {},
        productOthers: data.process?.productOthers ?? {},
        isFailed: data.process?.isFailed ?? false,

        note: data.process?.note ?? "",
        dateStart: data.process?.dateStart ?? null,
        dateEnd: data.process?.dateEnd ?? null,

        consumerProduct: Object.entries<DocumentData>(
          data.process?.consumerProduct ?? {}
        ).reduce((res, cV) => {
          return {
            ...res,
            [cV[0]]: {
              qty: cV[1]?.qty ?? 0,
            },
          };
        }, {}),
        nestProduct: Object.entries<DocumentData>(
          data.process?.nestProduct ?? {}
        ).reduce((res, cV) => {
          if (cV[0] in SwallowNestType)
            return {
              ...res,
              [cV[0]]: {
                qty: cV[1]?.qty ?? 0,
              },
            };
          return res;
        }, {}),
      },
    } as HarvestDocData;

    if (unsub) {
      this.doc[id] = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]) {
      this.doc[id].data = docData;
    } else {
      console.error(`FAILED SETTING HARVEST DOC`);
    }
  }
  @Action({ rawError: true })
  [Actions.HARVEST_GET_SINGLE](payload: { hid: string }) {
    const hid = payload.hid;
    if (!this.doc[hid]?.unsub) {
      const unsub = onSnapshot(doc(db, "harvests", hid), (dbDoc) => {
        console.log("HARVEST_GET_SINGLE", dbDoc.data()?.farmer?.rbwId)
        this.context.dispatch(Actions.RBW_GET_SINGLE, { rbwid: dbDoc.data()?.farmer?.rbwId })
        this.context.dispatch(Actions.ACCOUNT_GET_SINGLE, { uid: dbDoc.data()?.farmer?.uid })
        this.context.commit(Mutations.HARVEST_SET_DOC, {
          id: dbDoc.id,
          data: dbDoc.data() ?? {},
          unsub: this.doc[hid]?.unsub ? null : unsub,
        });
      });
    }
  }

  @Mutation
  [Mutations.HARVEST_SET_PAGE_QUERY](payload: {
    key: HarvestPageQuery;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Mutation
  [Mutations.HARVEST_SET_SEARCH_TEXT](payload: string) {
    this.searchText = payload
  }

  @Mutation
  [Mutations.HARVEST_SET_TEXT_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.textQuery[key]) {
      this.textQuery[key] = [...this.textQuery[key], ...ids];
    } else {
      this.textQuery[key] = ids;
    }
  }

  @Action
  [Actions.HARVEST_POPULATE_PAGE_QUERY](payload: {
    key: HarvestPageQuery;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    let dbQuery: Query<DocumentData>;

    const harvestIndexQuery = query(
      collection(db, "queryIndices"),
      where("target", "==", "harvest"),
      where("total", "!=", 0),
    );
    this.context.commit(Mutations.HARVEST_SET_INDEX_SUB, {
      unsub: onSnapshot(harvestIndexQuery, (userIndexDocs) => {
        userIndexDocs.docs.forEach((x)=>{
          Object.entries(x.data().harvest).forEach((y) => {
            this.context.commit(Mutations.HARVEST_SET_INDEX, {
              id: y[0],
              data: y[1] as DocumentData,
            });
          });
        })
      }, (e)=>console.log(e)),
    });
  }

  @Action
  async [Actions.HARVEST_POPULATE_TEXT_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, "harvests"),
      where("versionNo", "==", 0),
      orderBy("farmerName"),
      where("farmerName", "==", key),
      where("farmerName", "==", key + "~"),
      // limit(50)
    );

    const dbDocs = await getDocs(dbQuery);
    const ids: Array<string> = [];

    dbDocs.forEach((dbDoc) => {
      ids.push(dbDoc.id);
      if (!this.doc[dbDoc.id]) {
        this.context.commit(Mutations.HARVEST_SET_DOC, {
          id: dbDoc.id,
          data: dbDoc.data(),
          unsub: onSnapshot(doc(db, "harvests", dbDoc.id), (dbDoc) => {
            this.context.commit(Mutations.HARVEST_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
            });
          }),
        });
      }
    });

    this.context.commit(Mutations.HARVEST_SET_TEXT_QUERY, {
      key: key,
      ids: ids,
      append: append,
    });
  }

  @Action
  [Actions.HARVEST_UPDATE_DOC](payload: {
    id: string;
    harvest: { [x: string]: any };
  }) {
    const id = payload.id;
    const harvest = payload.harvest;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const harvestRef = doc(db, "harvests", id);
    batch.update(harvestRef, harvest);

    console.log(id, harvest);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.HARVEST_CREATE](payload: {
    id: string;
    harvest: DocumentData;
  }) {
    const id = payload.id;
    const harvest = payload.harvest;

    // Get a new write batch
    const batch = writeBatch(db);

    if (harvest) {
      // Set profile value
      const harvestRef = doc(db, "harvests", id);
      batch.set(harvestRef, {
        versionNo: 0,
        ...harvest,
      });
    }

    // Commit the batch
    return batch.commit();
  }
}

export { HarvestPageQuery, HarvestStoreState, HarvestDoc, HarvestDocData };
