import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
  addDoc,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

enum ChatType {
  text = "text",
  image = "image",
}

interface ChatStoreState {
  consultation: { uid: string | null };
  doc: { [key: string]: { [key: string]: ChatDoc } };
  pageQuery: { [key: string]: Array<string> };
}

interface ChatDoc {
  uid: string;
  cid: string;
  data: ChatDocData;
  unsub: Unsubscribe | null;
}

interface ChatDocData {
  uid: string;
  cid: string;
  senderId: string;
  versionNo: number;
  date: Timestamp | null;
  message: string;
  type: ChatType;
}

@Module
export default class ChatModule extends VuexModule implements ChatStoreState {
  consultation = { uid: null as string | null }
  doc = {} as { [key: string]: { [key: string]: ChatDoc } };
  pageQuery = {} as { [key: string]: Array<string> };

  @Mutation
  [Mutations.CHAT_SET_CONSULTATION](payload: {
    uid: string;
  }) {
    const uid = payload.uid;
    this.consultation.uid = uid;
  }

  @Mutation
  [Mutations.CHAT_SET_DOC](payload: {
    uid: string;
    cid: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const uid = payload.uid;
    const cid = payload.cid;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      uid: data.uid ?? uid,
      cid: data.cid ?? cid,
      senderId: data.senderId ?? "",
      versionNo: data.versionNo ?? 0,
      date: data.date ?? null,
      message: data.message ?? "",
      type: data.type in ChatType ? data.type : ChatType.text,
    } as ChatDocData;

    if (unsub) {
      if (!this.doc[uid]) this.doc[uid] = {};
      this.doc[uid][cid] = {
        uid: uid,
        cid: cid,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[uid] && this.doc[uid][cid]) {
      this.doc[uid][cid].data = docData;
    } else {
      console.error(`FAILED SETTING CHAT DOC`);
    }
  }

  @Mutation
  [Mutations.CHAT_SET_PAGE_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Action
  [Actions.CHAT_POPULATE_PAGE_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, `consultations/${key}/chats`),
      where("versionNo", "==", 0),
      orderBy("date")
      // limit(50)
    );
    console.log(`consultations/${key}/chats`);

    getDocs(dbQuery)
      .then((dbDocs) => {
        const ids: Array<string> = [];
        const uids: Array<string> = [];

        dbDocs.forEach((dbDoc) => {
          if(dbDoc.data().uid && !uids.includes(dbDoc.data().uid))
            uids.push(dbDoc.data().uid);
          ids.push(dbDoc.id);
          if (!this.doc[dbDoc.id]) {
            const unsub = onSnapshot(
              doc(db, `consultations/${key}/chats`, dbDoc.id),
              (dbDoc) => {
                this.context.commit(Mutations.CHAT_SET_DOC, {
                  uid: key,
                  cid: dbDoc.id,
                  data: dbDoc.data(),
                  unsub: unsub,
                });
              }
            );
            this.context.commit(Mutations.CHAT_SET_DOC, {
              uid: key,
              cid: dbDoc.id,
              data: dbDoc.data(),
              unsub: unsub,
            });
          }
        });

        uids.forEach((uid)=>{
          this.context.dispatch(Actions.ACCOUNT_GET_SINGLE, { uid: uid }, {root: true})
        })

        this.context.commit(Mutations.CHAT_SET_PAGE_QUERY, {
          key: key,
          ids: ids,
          append: append,
        });
      })
      .catch((error) => console.log("owo", error.message));
  }

  @Action({ rawError: true })
  [Actions.CHAT_START_CONSULTATION](payload: {
    uid: string;
  }) {
    const uid = payload.uid;
    this.context.commit(Mutations.CHAT_SET_CONSULTATION, {
      uid: uid,
    });
  }

  @Action({ rawError: true })
  [Actions.CHAT_UPDATE_DOC](payload: {
    uid: string;
    cid: string;
    chat: { [x: string]: any };
  }) {
    const uid = payload.uid;
    const cid = payload.cid;
    const chat = payload.chat;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const chatRef = doc(db, `consultations/${uid}/chats`, cid);
    batch.update(chatRef, chat);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.CHAT_CREATE](payload: { uid: string; chat: DocumentData }) {
    const uid = payload.uid;
    const chat = payload.chat;
    console.log(`consultations/${uid}/chats`)
    const docRef = await addDoc(
      collection(db, `consultations/${uid}/chats`),
      chat
    );
    return docRef.id;
  }
}

export { ChatStoreState, ChatDoc, ChatDocData, ChatType };
