import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
  QuerySnapshot,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

export enum SubscriptionType {
  mitra = "mitra",
  binaan = "binaan",
  free = "free",
}

interface SubscriptionStoreState {
  doc: { [key: string]: { [key: string]: SubscriptionDoc } };
  pageQuery: { [key: string]: Array<string> };
  account: { [key: string]: SubscriptionAccountDoc }
}

interface SubscriptionAccountDoc {
  uid: string;
  docs: Array<SubscriptionDocData>;
  unsub: Unsubscribe | null;
}

interface SubscriptionDoc {
  srid: string;
  uid: string;
  data: SubscriptionDocData;
  unsub: Unsubscribe | null;
}

interface SubscriptionDocData {
  uid: string;
  srid: string;
  versionNo: number;
  dateStart: Timestamp | null;
  dateEnd: Timestamp | null;
  receiptImageURL: string;
  price: number;
  type: SubscriptionType;
  duration: {
    period: number;
    unit: string;
  }
}

@Module
export default class SubscriptionModule
  extends VuexModule
  implements SubscriptionStoreState
{
  errorMessage = null;
  doc = {} as { [key: string]: { [key: string]: SubscriptionDoc } };
  pageQuery = {} as { [key: string]: Array<string> };
  account = {} as SubscriptionModule["account"]

  @Mutation
  [Mutations.SUBSCRIPTION_SET_DOC](payload: {
    uid: string;
    sid: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const uid = payload.uid;
    const sid = payload.sid;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      uid: uid,
      srid: sid,
      versionNo: data.versionNo ?? 0,
      dateStart: data.dateStart ?? null,
      dateEnd: data.dateEnd ?? null,
      receiptImageURL: data.imageURL ?? data.receiptImageURL ?? "",
      price: data.price ?? 0,
      type:
        data.type in SubscriptionType
          ? data.type
          : SubscriptionType.free,
      duration: {
        period: data.duration?.period ?? 0,
        unit: data.duration?.unit ?? "",
      }
    } as SubscriptionDocData;

    if (unsub) {
      if (!this.doc[sid]) this.doc[sid] = {};
      this.doc[sid][uid] = {
        srid: uid,
        uid: sid,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[sid] && this.doc[sid][uid]) {
      this.doc[sid][uid].data = docData;
    } else {
      console.error(`FAILED SETTING SUBSCRIPTION DOC`);
    }
  }

  @Mutation
  [Mutations.SUBSCRIPTION_SET_ACCOUNT](payload: {
    uid: string;
    querySnapshot: QuerySnapshot<DocumentData>;
    unsub: Unsubscribe | undefined;
  }) {
    const uid = payload.uid;
    const querySnapshot = payload.querySnapshot;
    const unsub = payload.unsub;

    const docs = [] as Array<SubscriptionDocData>
    querySnapshot.docs.forEach((doc)=>{
      docs.push({
        uid: uid,
        srid: doc.id,
        versionNo: doc.data().versionNo ?? 0,
        dateStart: doc.data().dateStart ?? null,
        dateEnd: doc.data().dateEnd ?? null,
        receiptImageURL: doc.data().imageURL ?? doc.data().receiptImageURL ?? "",
        price: doc.data().price ?? 0,
        type:
          doc.data().type in SubscriptionType
            ? doc.data().type
            : SubscriptionType.free,
        duration: {
          period: doc.data().duration?.period ?? 0,
          unit: doc.data().duration?.unit ?? "",
        }
      } as SubscriptionDocData)
    })

    if (!this.account[uid]){
      this.account[uid] = {
        uid: uid,
        docs: docs,
        unsub: unsub ?? null
      }
    }else {
      this.account[uid].uid = uid
      this.account[uid].docs = docs
      this.account[uid].unsub?.()
      this.account[uid].unsub = unsub
    };

    console.log("this.account[uid]", this.account[uid])
  }

  @Mutation
  [Mutations.SUBSCRIPTION_SET_PAGE_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Action
  [Actions.SUBSCRIPTION_POPULATE_ACCOUNT](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, `users/${key}/subscriptionRecords`),
      where("versionNo", "==", 0),
      orderBy("dateStart", "desc"),
      // limit(50)
    );
    const unsubs = onSnapshot(dbQuery, (querySnapshot) => {
      this.context.commit(Mutations.SUBSCRIPTION_SET_ACCOUNT, {
        uid: key,
        querySnapshot: querySnapshot,
        unsub: unsubs,
      });
    });
  }

  @Action
  [Actions.SUBSCRIPTION_POPULATE_PAGE_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, `users/${key}/subscriptionRecords`),
      where("versionNo", "==", 0),
      orderBy("dateStart", "desc"),
      // limit(50)
    );
    console.log(`users/${key}/subscriptionRecords`);

    getDocs(dbQuery)
      .then((dbDocs) => {
        console.log(dbDocs.docs.map((e) => e.id));
        const ids: Array<string> = [];

        dbDocs.forEach((dbDoc) => {
          ids.push(dbDoc.id);
          if (!this.doc[dbDoc.id]) {
            const unsub = onSnapshot(
              doc(db, `users/${key}/subscriptionRecords`, dbDoc.id),
              (dbDoc) => {
                this.context.commit(Mutations.SUBSCRIPTION_SET_DOC, {
                  uid: dbDoc.id,
                  sid: key,
                  data: dbDoc.data(),
                  unsub: unsub,
                });
              }
            );
            this.context.commit(Mutations.SUBSCRIPTION_SET_DOC, {
              uid: dbDoc.id,
              sid: key,
              data: dbDoc.data(),
              unsub: unsub,
            });
          }
        });

        this.context.commit(Mutations.SUBSCRIPTION_SET_PAGE_QUERY, {
          key: key,
          ids: ids,
          append: append,
        });
      })
      .catch((error) => console.log("owo", error.message));
  }

  @Action({ rawError: true })
  [Actions.SUBSCRIPTION_UPDATE_DOC](payload: {
    uid: string;
    sid: string;
    subscription: { [x: string]: any };
  }) {
    const uid = payload.uid;
    const sid = payload.sid;
    const subscription = payload.subscription;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const subscriptionRef = doc(db, `users/${sid}/subscriptionRecords`, uid);
    batch.update(subscriptionRef, subscription);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.ARTICLE_CREATE](payload: {
    id: string;
    subscription: DocumentData;
  }) {
    const id = payload.id;
    const subscription = payload.subscription;

    // Get a new write batch
    const batch = writeBatch(db);

    if (subscription) {
      // Set profile value
      const subscriptionRef = doc(db, "subscriptionRecords", id);
      batch.set(subscriptionRef, {
        versionNo: 0,
        ...subscription,
      });
    }

    // Commit the batch
    return batch.commit();
  }
}

export { SubscriptionStoreState, SubscriptionDoc, SubscriptionDocData };
