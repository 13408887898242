import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

export enum ParticipantStatus {
  registered = "registered",
  onVerify = "onVerify",
  payment = "payment",
  rejected = "rejected",
  cancelled = "cancelled",
}

interface ParticipantStoreState {
  doc: { [key: string]: { [key: string]: ParticipantDoc } };
  pageQuery: { [key: string]: Array<string> };
}

interface ParticipantDoc {
  uid: string;
  sid: string;
  data: ParticipantDocData;
  unsub: Unsubscribe | null;
}

interface ParticipantDocData {
  uid: string;
  sid: string;
  versionNo: number;
  lastUpdated: Timestamp | null;
  receiptImageURL: string;
  status: ParticipantStatus;
}

@Module
export default class ParticipantModule
  extends VuexModule
  implements ParticipantStoreState
{
  errorMessage = null;
  doc = {} as { [key: string]: { [key: string]: ParticipantDoc } };
  pageQuery = {} as { [key: string]: Array<string> };

  @Mutation
  [Mutations.PARTICIPANT_SET_DOC](payload: {
    uid: string;
    sid: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const uid = payload.uid;
    const sid = payload.sid;
    const data = payload.data;
    const unsub = payload.unsub;

    console.log(data.status ,data.status in ParticipantStatus)

    const docData = {
      uid: uid,
      sid: sid,
      versionNo: data.versionNo ?? 0,
      lastUpdated: data.lastUpdated ?? null,
      receiptImageURL: data.receiptImageURL ?? "",
      status:
        data.status in ParticipantStatus
          ? data.status
          : ParticipantStatus.onVerify,
    } as ParticipantDocData;

    if (unsub) {
      if (!this.doc[sid]) this.doc[sid] = {};
      this.doc[sid][uid] = {
        uid: uid,
        sid: sid,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[sid] && this.doc[sid][uid]) {
      this.doc[sid][uid].data = docData;
    } else {
      console.error(`FAILED SETTING PARTICIPANT DOC`);
    }
  }

  @Mutation
  [Mutations.PARTICIPANT_SET_PAGE_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Action
  [Actions.PARTICIPANT_POPULATE_PAGE_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, `seminars/${key}/participants`),
      where("versionNo", "==", 0),
      orderBy("lastUpdated"),
      limit(50)
    );
    console.log(`seminars/${key}/participants`);

    getDocs(dbQuery)
      .then((dbDocs) => {
        console.log(dbDocs.docs.map((e) => e.id));
        const ids: Array<string> = [];

        dbDocs.forEach((dbDoc) => {
          ids.push(dbDoc.id);
          if (!this.doc[dbDoc.id]) {
            const unsub = onSnapshot(
              doc(db, `seminars/${key}/participants`, dbDoc.id),
              (dbDoc) => {
                this.context.commit(Mutations.PARTICIPANT_SET_DOC, {
                  uid: dbDoc.id,
                  sid: key,
                  data: dbDoc.data(),
                  unsub: unsub,
                });
              }
            );
            this.context.commit(Mutations.PARTICIPANT_SET_DOC, {
              uid: dbDoc.id,
              sid: key,
              data: dbDoc.data(),
              unsub: unsub,
            });
          }
        });

        this.context.commit(Mutations.PARTICIPANT_SET_PAGE_QUERY, {
          key: key,
          ids: ids,
          append: append,
        });
      })
      .catch((error) => console.log("owo", error.message));
  }

  @Action({ rawError: true })
  [Actions.PARTICIPANT_UPDATE_DOC](payload: {
    uid: string;
    sid: string;
    participant: { [x: string]: any };
  }) {
    const uid = payload.uid;
    const sid = payload.sid;
    const participant = payload.participant;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const participantRef = doc(db, `seminars/${sid}/participants`, uid);
    batch.update(participantRef, participant);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.ARTICLE_CREATE](payload: {
    id: string;
    participant: DocumentData;
  }) {
    const id = payload.id;
    const participant = payload.participant;

    // Get a new write batch
    const batch = writeBatch(db);

    if (participant) {
      // Set profile value
      const participantRef = doc(db, "participants", id);
      batch.set(participantRef, {
        versionNo: 0,
        ...participant,
      });
    }

    // Commit the batch
    return batch.commit();
  }
}

export { ParticipantStoreState, ParticipantDoc, ParticipantDocData };
