import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { UserAuthorization } from "@/store/modules/AccountModule";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    meta: { requiresAuth: true },
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { requiresAdmin: true },
        // component: () => import("@/views/Dashboard.vue"),
        component: () => import("@/views/dashboard/account/User.vue"),
      },
      {
        path: "/account",
        redirect: "/account/user",
        meta: { requiresAdmin: true },
        children: [
          {
            path: "/account/user",
            name: "user",
            component: () => import("@/views/dashboard/account/User.vue"),
          },
          {
            path: "/account/superteam",
            name: "superteam",
            component: () => import("@/views/dashboard/account/Superteam.vue"),
          },
          {
            path: "/account/admin",
            name: "admin",
            component: () => import("@/views/dashboard/account/Admin.vue"),
          },
        ],
      },
      {
        path: "/farm",
        redirect: "/farm/harvest",
        children: [
          {
            path: "/farm/harvest",
            name: "harvest",
            component: () => import("@/views/dashboard/farm/Harvest.vue"),
          },
          {
            path: "/farm/upgrade",
            name: "upgrade",
            meta: { requiresAdmin: true },
            component: () => import("@/views/dashboard/farm/Upgrade.vue"),
          },
          {
            path: "/farm/price",
            name: "price",
            meta: { requiresAdmin: true },
            component: () => import("@/views/dashboard/farm/Price.vue"),
          },
        ],
      },
      {
        path: "/rbw",
        redirect: "/rbw/building",
        children: [
          {
            path: "/rbw/building",
            name: "building",
            component: () => import("@/views/dashboard/farm/Building.vue"),
          },
        ],
      },
      {
        path: "/marketplace",
        redirect: "/marketplace/consumer",
        meta: { requiresAdmin: true },
        children: [
          {
            path: "/marketplace/consumer",
            name: "consumer",
            component: () =>
              import("@/views/dashboard/marketplace/Consumer.vue"),
          },
          {
            path: "/marketplace/farmer",
            name: "farmer",
            component: () => import("@/views/dashboard/marketplace/Farmer.vue"),
          },
          {
            path: "/marketplace/transaction",
            name: "transaction",
            component: () =>
              import("@/views/dashboard/marketplace/Transaction.vue"),
          },
        ],
      },
      {
        path: "/content",
        redirect: "/content/seminar",
        meta: { requiresAdmin: true },
        children: [
          {
            path: "/content/seminar",
            name: "seminar",
            component: () => import("@/views/dashboard/content/Seminar.vue"),
          },
          {
            path: "/content/video",
            name: "video",
            component: () => import("@/views/dashboard/content/Course.vue"),
          },
          {
            path: "/content/article",
            name: "article",
            component: () => import("@/views/dashboard/content/Article.vue"),
          },
          {
            path: "/content/consultation",
            name: "consultation",
            component: () =>
              import("@/views/dashboard/content/Consultation.vue"),
          },
        ],
      },
      {
        path: "/setting",
        name: "setting",
        // component: () => import("@/views/Dashboard.vue"),
        component: () => import("@/views/dashboard/Setting.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  await store.dispatch(Actions.AUTH_VERIFY_USER, to.meta.requiresAuth ? next : undefined);
  if(!store.state.AnalyticModule.initialized) store.dispatch(Actions.ANALYTIC_INIT);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (to.meta.requiresAuth && !store.state.AuthModule.auth) {
    next("sign-in");
  } else if (store.state.AuthModule.user.doc?.authorization !== UserAuthorization.admin && to.meta.requiresAdmin) {
    // next("farm/harvest");
    next();
  } else {
    next();
  }
});

export default router;
