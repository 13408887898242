import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

import { auth, db } from "@/core/services/FirebaseService";
import {
  setDoc,
  doc,
  writeBatch,
  query,
  collection,
  limit,
  orderBy,
  where,
  onSnapshot,
  startAfter,
  Timestamp,
  GeoPoint,
  getDocs,
  Query,
  DocumentData,
} from "firebase/firestore";
import { Unsubscribe } from "@firebase/util";

enum ProductPageQuery {
  all = "all",
  consumer = "consumer",
  farmer = "farmer",
}

enum MarketplaceType {
  farmer = "farmer",
  consumer = "consumer",
}

interface ProductStoreState {
  doc: { [key: string]: ProductDoc };
  pageQuery: { [key in ProductPageQuery]: Array<string> };
  textQuery: { [key: string]: Array<string> };
  searchText: String;
}

interface ProductDoc {
  id: string;
  data: ProductDocData;
  unsub: Unsubscribe | null;
}

interface ProductDocData {
  pid: string;
  isShow: boolean;
  marketplace: MarketplaceType;
  imageURL: string;
  name: string;
  description: string;
  price: number;
  weight: number;
  stock: number;
  tags: Array<string>;
  versionNo: number;
}

@Module
export default class ProductModule
  extends VuexModule
  implements ProductStoreState
{
  errorMessage = null;
  searchText = "";
  doc = {} as { [key: string]: ProductDoc };
  pageQuery = {} as { [key in ProductPageQuery]: Array<string> };
  textQuery = {} as { [key: string]: Array<string> };

  @Mutation
  [Mutations.PRODUCT_SET_DOC](payload: {
    id: string;
    data: DocumentData;
    unsub: Unsubscribe | undefined;
  }) {
    const id = payload.id;
    const data = payload.data;
    const unsub = payload.unsub;

    const docData = {
      pid: id,
      isShow: data.isShow ?? false,
      marketplace:
        data.marketplace in MarketplaceType
          ? data.marketplace
          : MarketplaceType.consumer,
      imageURL: data.imageURL ?? "",
      name: data.name ?? "",
      description: data.description ?? "",
      price: data.price ?? 0,
      weight: data.weight ?? 0,
      stock: data.stock ?? 0,
      tags: data.tags ?? [],
      versionNo: data.versionNo ?? 0,
    } as ProductDocData;

    if (unsub) {
      this.doc[id] = {
        id: id,
        data: docData,
        unsub: unsub,
      };
    } else if (this.doc[id]) {
      this.doc[id].data = docData;
    } else {
      console.error(`FAILED SETTING PRODUCT DOC`);
    }
  }

  @Mutation
  [Mutations.PRODUCT_SET_SEARCH_TEXT](payload: string) {
    this.searchText = payload
  }

  @Mutation
  [Mutations.PRODUCT_SET_PAGE_QUERY](payload: {
    key: ProductPageQuery;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.pageQuery[key]) {
      this.pageQuery[key] = [...this.pageQuery[key], ...ids];
    } else {
      this.pageQuery[key] = ids;
    }
  }

  @Mutation
  [Mutations.PRODUCT_SET_TEXT_QUERY](payload: {
    key: string;
    ids: Array<string>;
    append: boolean;
  }) {
    const key = payload.key;
    const ids = payload.ids;
    const append = payload.append;

    if (append && this.textQuery[key]) {
      this.textQuery[key] = [...this.textQuery[key], ...ids];
    } else {
      this.textQuery[key] = ids;
    }
  }

  @Action
  [Actions.PRODUCT_POPULATE_PAGE_QUERY](payload: {
    key: ProductPageQuery;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    let dbQuery: Query<DocumentData>;
    switch (key) {
      case ProductPageQuery.consumer:
        dbQuery = query(
          collection(db, "products"),
          where("versionNo", "==", 0),
          where("marketplace", "==", "consumer"),
          orderBy("name"),
          // limit(50)
        );
        break;
      case ProductPageQuery.farmer:
        dbQuery = query(
          collection(db, "products"),
          where("versionNo", "==", 0),
          where("marketplace", "==", "farmer"),
          orderBy("name"),
          // limit(50)
        );
        break;
      case ProductPageQuery.all:
      default:
        dbQuery = query(
          collection(db, "products"),
          where("versionNo", "==", 0),
          orderBy("name"),
          // limit(50)
        );
        break;
    }

    getDocs(dbQuery)
      .then((dbDocs) => {
        console.log(
          "done",
          dbDocs.docs.map((e) => e.id)
        );
        const ids: Array<string> = [];

        dbDocs.forEach((dbDoc) => {
          ids.push(dbDoc.id);
          if (!this.doc[dbDoc.id]) {
            const unsub = onSnapshot(doc(db, "products", dbDoc.id), (dbDoc) => {
              this.context.commit(Mutations.PRODUCT_SET_DOC, {
                id: dbDoc.id,
                data: dbDoc.data(),
              });
            });
            this.context.commit(Mutations.PRODUCT_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
              unsub: unsub,
            });
          }
        });

        this.context.commit(Mutations.PRODUCT_SET_PAGE_QUERY, {
          key: key,
          ids: ids,
          append: append,
        });
      })
      .catch((error) => console.log("owo", error.message));
  }

  @Action
  async [Actions.PRODUCT_POPULATE_TEXT_QUERY](payload: {
    key: string;
    append: boolean;
  }) {
    const key = payload.key;
    const append = payload.append;

    const dbQuery = query(
      collection(db, "products"),
      where("versionNo", "==", 0),
      orderBy("farmerName"),
      where("farmerName", "==", key),
      where("farmerName", "==", key + "~"),
      // limit(50)
    );

    const dbDocs = await getDocs(dbQuery);
    const ids: Array<string> = [];

    dbDocs.forEach((dbDoc) => {
      ids.push(dbDoc.id);
      if (!this.doc[dbDoc.id]) {
        this.context.commit(Mutations.PRODUCT_SET_DOC, {
          id: dbDoc.id,
          data: dbDoc.data(),
          unsub: onSnapshot(doc(db, "products", dbDoc.id), (dbDoc) => {
            this.context.commit(Mutations.PRODUCT_SET_DOC, {
              id: dbDoc.id,
              data: dbDoc.data(),
            });
          }),
        });
      }
    });

    this.context.commit(Mutations.PRODUCT_SET_TEXT_QUERY, {
      key: key,
      ids: ids,
      append: append,
    });
  }

  @Action({ rawError: true })
  [Actions.PRODUCT_UPDATE_DOC](payload: {
    id: string;
    product: { [x: string]: any };
  }) {
    console.log("RBW_UPDATE_DOC");
    const id = payload.id;
    const product = payload.product;

    // Get a new write batch
    const batch = writeBatch(db);

    // Set profile value
    const productRef = doc(db, "products", id);
    batch.update(productRef, product);

    console.log(id, product);

    // Commit the batch
    return batch.commit();
  }

  @Action
  async [Actions.PRODUCT_CREATE](payload: {
    id: string;
    product: DocumentData;
  }) {
    const id = payload.id;
    const product = payload.product;

    // Get a new write batch
    const batch = writeBatch(db);

    if (product) {
      // Set profile value
      const productRef = doc(db, "products", id);
      batch.set(productRef, {
        versionNo: 0,
        ...product,
      });
    }

    // Commit the batch
    return batch.commit();
  }
}

export {
  ProductPageQuery,
  ProductStoreState,
  ProductDoc,
  ProductDocData,
  MarketplaceType,
};
